.icons_box{
    height: 70px;
    width: 70px;
    /*background-color: yellow;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 40%;
    margin-right: 40%;
}
/*.service_box img{*/
    /*height: 200px;*/
    /*margin-bottom: 20px;*/

/*}*/

.contents_box p{
    font-size: 20px !important;
    color: black !important;
    /*font-weight: ;*/
}
